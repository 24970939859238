import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import ScrollToAnchor from './helpers/ScrollToAnchor';
import './assets/styles/reset.css';
import './assets/styles/vars.css';
import './assets/styles/typography.sass';
import './assets/styles/main.sass';

// Initialize Sentry
Sentry.init({
  dsn: "https://f03fbfcd5ced567b312a5f1254b6a451@o4508648760147968.ingest.us.sentry.io/4508694571450369",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "https://utilitycloset.mmerch.xyz"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Helmet prioritizeSeoTags>
          <meta charSet="utf-8" />
          <title>mmERCH</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            rel="stylesheet"
          />
          <link rel="icon" href="../src/assets/img/logo.svg" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1, minimum-scale=1"
          />
          <meta name="theme-color" content="#c7c4b9" />
          <meta name="description" content="Neo-Couture Non-Fungible Fashion" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="black-translucent"
          />
          <meta name="apple-mobile-web-app-title" content="mmERCH" />
        </Helmet>
        <ScrollToTop />
        <ScrollToAnchor />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
