import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);

function RunGsapEvendCard() {
  useGSAP(() => {
    gsap.to('.animateEventCard', {
      opacity: 1,
      y: 0,
      ease: 'power1.inOut',
      duration: 0.66,
      stagger: {
        amount: 0.33,
      },
    });
  });

  return null;
}

export default RunGsapEvendCard;
