import styles from './TokenCard.module.sass';
import { Link } from 'react-router-dom';
import RunGsapTokenCard from '../../helpers/runGsapTokenCard';

type ModuleProps = {
  key?: number;
  link?: string;
  img?: string;
};

export default function TokenCard({ key, link, img }: ModuleProps) {
  return (
    <>
      <RunGsapTokenCard />
      <div
        className={`${styles.tokenCard} ${
          link ? styles.active : 'inactive'
        } animateTokenCard`}
        key={key}
      >
        {link ? (
          <Link to={link}>
            <img src={img} alt="token" />
          </Link>
        ) : (
          <span>
            Experience
            <br />
            Token
          </span>
        )}
      </div>
    </>
  );
}
