import './Modal.sass';
import React, { useState, useRef, useEffect } from 'react';
import Button, { ButtonStretch, ButtonState } from '../Button/Button';
import ButtonContainer, {
  ButtonContainerDirection,
} from '../ButtonContainer/ButtonContainer';
import useSwipeVer from '../../helpers/useSwipeVer';

type Props = {
  children?: React.ReactNode;
  title?: string;
  btnTitle?: string;
  isOpen: boolean;
  hasCloseBtn?: boolean;
  hasCancel?: boolean;
  onClose?: () => void;
};

export default function Modal({
  children,
  title,
  btnTitle,
  isOpen,
  hasCloseBtn,
  hasCancel,
  onClose,
}: Props) {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    setModalOpen(isOpen);

    // Disable scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'auto';
    }
  }, [isOpen]);

  // useEffect(() => {
  //   const modalElement = modalRef.current;
  //   if (modalElement) {
  //     if (isModalOpen) {
  //       modalElement.showModal();
  //     } else {
  //       modalElement.close();
  //     }
  //   }
  // }, [isModalOpen]);

  // useEffect(() => {
  //   var dialog = document.querySelector('dialog');
  //   dialog?.addEventListener('click', function (event) {
  //     var rect = dialog.getBoundingClientRect();
  //     var isInDialog =
  //       rect.top <= event.clientY &&
  //       event.clientY <= rect.top + rect.height &&
  //       rect.left <= event.clientX &&
  //       event.clientX <= rect.left + rect.width;
  //     if (!isInDialog) {
  //       dialog.close();
  //     }
  //   });
  // }, []);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  // Swipe Input
  const swipeHandlers = useSwipeVer({
    onSwipedLeft: () => {},
    onSwipedRight: () => {},
    onSwipedUp: () => {},
    onSwipedDown: handleCloseModal,
  });

  return (
    <div
      ref={modalRef}
      onKeyDown={handleKeyDown}
      id="modal"
      className="modal"
      data-state={isModalOpen ? 'open' : 'closed'}
    >
      <div className="modal-Container">
        <div className="modal-Content" {...swipeHandlers}>
          <div
            onClick={handleCloseModal}
            id="closeModal"
            className="modal-CloseBar"
          >
            <div className="modal-CloseBarItem" />
          </div>
          <div className="modal-Title typeDislay bold">{title}</div>
          {children}
          {hasCloseBtn && (
            <ButtonContainer direction={ButtonContainerDirection.Horizontal}>
              <Button
                stretch={ButtonStretch.Horizontal}
                onClick={handleCloseModal}
              >
                {btnTitle || 'Close'}
              </Button>
              {hasCancel && (
                <Button state={ButtonState.Action} onClick={handleCloseModal}>
                  cancel
                </Button>
              )}
            </ButtonContainer>
          )}
        </div>
      </div>
      <div className="modal-Background" onClick={handleCloseModal} />
    </div>
  );
}
