import styles from './EventCard.module.sass';
import ButtonContainer, {
  ButtonContainerAlignment,
} from '../ButtonContainer/ButtonContainer';
import Button, { ButtonForm } from '../Button/Button';
import IconArrow from '../Icons/IconArrow';
import RunGsapEvendCard from '../../helpers/RunGsapEvendCard';

type ModuleProps = {
  hasButton?: boolean;
  background?: string;
  path?: string;
};

export default function Event({ hasButton, background, path }: ModuleProps) {
  return (
    <>
      <RunGsapEvendCard />
      <div
        className={`${styles.eventCard} animateEventCard`}
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {hasButton && (
          <ButtonContainer
            className={styles.buttonContainer}
            align={ButtonContainerAlignment.Right}
          >
            <Button form={ButtonForm.Forward} path={'/eventSingle/' + path}>
              <div className="icon">
                <IconArrow direction="forward" />
              </div>
            </Button>
          </ButtonContainer>
        )}
      </div>
    </>
  );
}
