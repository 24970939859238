import './ButtonContainer.sass';

export enum ButtonContainerAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Stretch = 'stretch',
  RightBottom = 'rightBottom',
}

export enum ButtonContainerDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

type ModuleProps = {
  className?: string;
  children?: React.ReactNode;
  align?: ButtonContainerAlignment;
  direction?: ButtonContainerDirection;
};

export default function Module({
  className,
  children,
  align,
  direction,
}: ModuleProps) {
  return (
    <div className={`buttonContainer ${className} ${align} ${direction}`}>
      {children}
    </div>
  );
}
