import { useState } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import ButtonContainer, {
  ButtonContainerAlignment,
  ButtonContainerDirection,
} from '../../components/ButtonContainer/ButtonContainer';
import Button, {
  ButtonStretch,
  ButtonForm,
  ButtonState,
} from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import IconLocate from '../../components/Icons/IconLocate';
import Modal from '../../components/Modal/Modal';
import RunGsap from '../../helpers/RunGsap';
import getCookie from '../../helpers/getCookie';
import Loader from '../../components/Loader/Loader';
import { FETCH_STATUS } from '../../helpers/fetchStatus';
import { EventState } from '../../enums/events';
import DemoEvents from '../../data/demoEvents.json';
import { env } from 'process';

interface ErrorState {
  errorId: number;
  errorTitle: string;
  errorCopy: string;
}

function EventSingle(props: { nftData: any; eventData: any[] }) {
  const [jwt, setJwt] = useState<string | null>(null);
  const [haveToken, setHaveToken] = useState<boolean>(false);
  const isDevelopmentEnv = process.env.NODE_ENV === 'development';
  let [searchParams, setSearchParams] = useSearchParams('');
  const [eventState, setEventState] = useState<EventState>();
  const [eventData, setEventData] = useState<
    | {
        buttonText: string;
        buttonVariation: string;
        actionPerformed: boolean;
        actionCount: number;
        date: string;
        startEpoch: number;
        endEpoch: number;
        active: boolean;
        description: string;
        eventId: string;
        image: string;
        link: { linkShow: boolean; linkText: string; linkUrl: string };
        location: string;
        name: string;
        path: string;
      }
    | undefined
  >(undefined);
  const [ModalOpenCheckin, setModalOpenCheckin] = useState(false);
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [geolocationBtnState, setGeolocationBtnState] = useState<ButtonState>(
    ButtonState.Default
  );
  const [rsvpBtnState, setRsvpBtnState] = useState<ButtonState>(
    ButtonState.Default
  );
  const [hasError, setHasError] = useState<ErrorState>({
    errorId: 0,
    errorTitle: '',
    errorCopy: '',
  });
  const [responseData, setResponseData] = useState<any>({}); // TODO: Remove
  let nftData = props.nftData;
  const hoodieNumber = nftData.number;
  const { slug } = useParams();

  // Set EventData
  useEffect(() => {
    if (isDevelopmentEnv) {
      const eventArray = Array.isArray(DemoEvents) ? DemoEvents : [];
      const event = eventArray.find((event) => event.path === slug);
      setEventData(event);
    } else {
      const eventArray = Array.isArray(props.eventData) ? props.eventData : [];
      const event = eventArray.find((event) => event.path === slug);
      setEventData(event);
    }
  }, [slug, isDevelopmentEnv, props.eventData]);

  // Set eventActive
  useEffect(() => {
    if (eventData && eventData.active) {
      setEventState(EventState.Open);
    } else {
      setEventState(EventState.Closed);
    }
  }, [eventData]);

  // Set cookie
  useEffect(() => {
    setJwt(getCookie('__Secure-auth') || null);
  }, []);

  useEffect(() => {
    if (!!jwt) {
      setHaveToken(true);
    } else if (isDevelopmentEnv) {
      setHaveToken(true);
    } else {
      setHaveToken(false);
    }
  }, [jwt, isDevelopmentEnv]);

  function setGeolocationButtonState(): void {
    console.log('havetoken', haveToken);
    console.log('eventstate', eventState);
    console.log('eventData', eventData);
    if (!haveToken || eventState === EventState.Closed) {
      if (
        eventData?.buttonVariation === 'geolocation' &&
        eventData?.actionPerformed
      ) {
        setGeolocationBtnState(ButtonState.Completed);
        console.log('Event is past and button completed');
      } else {
        setGeolocationBtnState(ButtonState.Disabled);
        console.log('Event is past and button disabled');
      }
    } else if (haveToken && eventState === EventState.Open) {
      if (
        eventData?.buttonVariation === 'geolocation' &&
        eventData?.actionPerformed
      ) {
        setGeolocationBtnState(ButtonState.Completed);
        console.log('Event is active and button completed');
      }
      setGeolocationBtnState(ButtonState.Default);
      console.log('Event is active and button is enabled');
    }
  }

  function setRsvpButtonState(): void {
    console.log('havetoken', haveToken);
    console.log('eventstate', eventState);
    console.log('eventData', eventData);
    if (!haveToken || eventState === EventState.Closed) {
      if (eventData?.buttonVariation === 'RSVP' && eventData?.actionPerformed) {
        setRsvpBtnState(ButtonState.Completed);
        console.log('Event is past and button completed');
      } else {
        setRsvpBtnState(ButtonState.Disabled);
        console.log('Event is past and button disabled');
      }
    } else if (haveToken && eventState === EventState.Open) {
      if (eventData?.buttonVariation === 'RSVP' && eventData?.actionPerformed) {
        setRsvpBtnState(ButtonState.Editable);
        console.log('Event is active and button Editable');
      } else {
        setRsvpBtnState(ButtonState.Default);
        console.log('Event is active and button is enabled');
      }
    }
  }

  // Set Button States
  useEffect(() => {
    setGeolocationButtonState();
    setRsvpButtonState();
  }, [eventState, haveToken, eventData]);

  // HANDLE RSVP EMAIL LINK
  const emailSubject = 'I’m%20going%20to%20be%20in%20Marfa!';
  const emailBody = `Hi,%20mmERCH!\n \nIf%20there%20are%20spots%20left,%20I’d%20love%20to%20be%20added%20to%20the%20list%20for%20the%20private%20tour%20of%20the%20Chinati%20Foundation%20on%20November%2016,%202024!
${hoodieNumber ? `%0A%0AHoodie%20token%20${hoodieNumber}` : ''}`;
  const emailLink = `mailto:info@mmerch.xyz?subject=${emailSubject}&body=${emailBody}`;

  // HANDLE ERROR EMAIL :LINK
  const helpEmailLink = (number: number, hoodieNumber: string) => {
    const helpEmailSubject = 'Hi mmERCH! Some check in help needed';
    const helpEmailBody = `Hi, mmERCH! I need some help checking into ${
      eventData?.name
    }. 
    Event-ID: ${eventData ? eventData.eventId : 'UNDEFINED'}. 
    Hoodie-ID ${hoodieNumber ? hoodieNumber : 'UNDEFINED'}. 
    Issue-ID: ${number}`;
    return `mailto:info@mmerch.xyz?subject=${encodeURI(
      helpEmailSubject
    )}&body=${encodeURI(helpEmailBody)}`;
  };

  // HANDLE CHECK-IN

  const getPosition = () => {
    setStatus(FETCH_STATUS.LOADING);
    if (!navigator.geolocation) {
      setStatus(FETCH_STATUS.ERROR);
      setHasError({
        errorId: 4,
        errorTitle: 'Oh Oh...',
        errorCopy:
          'It seems your browser does not support geolocation. (1) Try a different browser or (2) ',
      });
      return;
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const data = {
            eventId: eventData?.eventId?.toString() || '',
            lat: position.coords.latitude.toString(),
            long: position.coords.longitude.toString(),
          };
          let uuid = searchParams.get('uuid') || '';
          try {
            const response = await axios.post(
              `https://router.mmerch.xyz/attendance/${uuid}`,
              data,
              {
                headers: {
                  'Content-Type': 'application/json',

                  // FIXME: this is the token for the test user, hardcoded for now - Token Expires 12/01/24
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );
            setStatus(FETCH_STATUS.SUCCESS);
            setResponseData(response.data);
          } catch (error) {
            console.error('Error sending check-in data:', error);
            setStatus(FETCH_STATUS.ERROR);
            setHasError({
              errorId: 5,
              errorTitle: 'Well, that didn’t work.',
              errorCopy:
                'There seems to be a server error. (1) Please check your connection (2) try again or (3) ',
            });
          }
        },
        (error) => {
          setStatus(FETCH_STATUS.ERROR);
          if (error.code === 0) {
            setHasError({
              errorId: 0,
              errorTitle: 'Something’s amiss',
              errorCopy:
                'It seems your location is unavailable. (1) Please try again, (2) check your settings (3) try a different browser or (4) ',
            });
          } else if (
            error.code === error.PERMISSION_DENIED ||
            error.code === 1
          ) {
            setHasError({
              errorId: 1,
              errorTitle: 'Uh…',
              errorCopy:
                'It seems you’ve denied us access. Perhaps it was an accident. So: (1) please try again (2) check settings (3) try a different browser or (4) ',
            });
          } else if (
            error.code === error.POSITION_UNAVAILABLE ||
            error.code === 2
          ) {
            setHasError({
              errorId: 2,
              errorTitle: 'Hmmm...curious....we can’t find you',
              errorCopy:
                'It seems your location is unavailable. (1) please try again, (2) check settings, (3) try a different browser or (4) ',
            });
          } else if (error.code === error.TIMEOUT || error.code === 3) {
            setHasError({
              errorId: 3,
              errorTitle: 'Ooops',
              errorCopy:
                'Hmm...that took too long. (1) please try again or (2) ',
            });
          }
          console.log('error', error);
        },
        {
          maximumAge: 0,
          timeout: 12000,
          enableHighAccuracy: true,
        }
      );
    }
  };

  const handleOpenModalCheckin = () => {
    setModalOpenCheckin(true);
    getPosition();
  };

  const handleCloseModalCheckin = () => {
    setModalOpenCheckin(false);
  };

  return (
    <div className="page page-Token">
      <RunGsap />
      <Module hasEvenCorners noTopPadding>
        <ModuleImage noTopPadding aspectRatio="13:16">
          {eventData && <img src={eventData.image} alt="nft" loading="lazy" />}
        </ModuleImage>
        <ModuleText>
          <div className="module-Title ">
            {eventData && <h1>{eventData.name}</h1>}
          </div>
          {eventData && (
            <div className="module-Text_List">
              <p className="typeSmall normal ">
                {eventData.date}
                <>
                  <br />
                  {eventData.location}
                </>
              </p>
              <p className="typeNormal normal ">{eventData.description}</p>
              <div className="module-Text_Link typeSmall normal ">
                <a href={eventData.link.linkUrl}>
                  {eventData.link.linkText}
                  <div className="icon">
                    <IconArrow direction="link" />
                  </div>
                </a>
              </div>
            </div>
          )}
        </ModuleText>
        <ButtonContainer
          align={ButtonContainerAlignment.Stretch}
          direction={ButtonContainerDirection.Vertical}
        >
          {/* RSVP  */}
          {eventData && eventData.buttonVariation === 'RSVP' && (
            <Button
              state={rsvpBtnState}
              stretch={ButtonStretch.Horizontal}
              exitingPath={emailLink}
            >
              {rsvpBtnState === ButtonState.Editable ? (
                <p>EDIT {eventData.buttonText}</p>
              ) : (
                <p>{eventData.buttonText}</p>
              )}
            </Button>
          )}
          {/* GEOLOCATION  */}
          {eventData && eventData.buttonVariation === 'geolocation' && (
            <>
              <Button
                state={geolocationBtnState}
                stretch={ButtonStretch.Horizontal}
                onClick={handleOpenModalCheckin}
              >
                <div className="icon" />
                {eventData.actionPerformed ? <p>COMPLETED</p> : <p>CHECK IN</p>}
                <div className="icon">
                  {eventData.actionPerformed === false && <IconLocate />}
                </div>
              </Button>
              {haveToken && eventState === EventState.Open && (
                <span className="typeSmall normal">
                  When you check in, you’ll be asked to share your location.
                </span>
              )}
              {!haveToken && eventState === EventState.Open && (
                <span className="typeSmall normal">
                  Please access this page using your mmERCH garment.
                </span>
              )}
              {eventState === EventState.Closed && (
                <span className="typeSmall normal">
                  Check in becomes available during the event (see dates).
                </span>
              )}
            </>
          )}
        </ButtonContainer>
      </Module>
      <ButtonContainer align={ButtonContainerAlignment.Left}>
        <Button form={ButtonForm.Back} path="/">
          <div className="icon">
            <IconArrow direction="left" />
          </div>
        </Button>
      </ButtonContainer>

      {/* CHECK IN MODAL */}
      <Modal
        isOpen={ModalOpenCheckin}
        onClose={handleCloseModalCheckin}
        hasCloseBtn
      >
        {status === FETCH_STATUS.LOADING && <Loader />}
        {status === FETCH_STATUS.SUCCESS && (
          <>
            <h1>Yes!</h1>
            <p>
              Your XP is being airdropped now! View it in your Wearable Wallet.
            </p>
          </>
        )}
        {status === FETCH_STATUS.ERROR && (
          <>
            <h1>{hasError.errorTitle}</h1>
            <p>
              {hasError.errorCopy}{' '}
              <a
                href={helpEmailLink(hasError.errorId, hoodieNumber)}
                target="_blank"
                rel="noreferrer"
              >
                send us an email
              </a>{' '}
              (info@mmerch.xyz).
            </p>
          </>
        )}
      </Modal>
      <div className="page-Bottom" />
    </div>
  );
}

export default EventSingle;
