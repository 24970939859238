import './Button.sass';
import { Link, useLocation } from 'react-router-dom';

export enum ButtonState {
  Default = 'default',
  Disabled = 'disabled',
  Completed = 'completed', // After actions are completed
  Editable = 'editable',
  Action = 'action', // A simple text underlined style for actions
}

export enum ButtonStretch {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum ButtonForm {
  Back = 'back',
  Forward = 'forward',
  Round = 'round',
}

type ButtonProps = {
  props?: any;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  path?: string;
  exitingPath?: string;
  state?: ButtonState;
  form?: ButtonForm;
  stretch?: ButtonStretch;
};

export default function Module({
  props,
  className,
  children,
  onClick,
  path,
  exitingPath,
  state = ButtonState.Default,
  form,
  stretch,
}: ButtonProps) {
  const { search } = useLocation();

  return (
    <>
      {onClick && (
        <div
          onClick={onClick}
          className={`button ${state} ${stretch} ${form} ${className}`}
        >
          {children}
        </div>
      )}

      {/* Keeps searchParams for navigation inside the app */}
      {path && (
        <Link
          className={`button ${state} ${stretch} ${form} ${className}`}
          to={`${path}${search}`}
          target="_self"
        >
          {children}
        </Link>
      )}

      {/* removes searchParams for navigation outside the app */}
      {exitingPath && (
        <Link
          className={`section button ${state} ${stretch} ${form} ${className}`}
          to={`${exitingPath}`}
          target="_blank"
        >
          {children}
        </Link>
      )}
    </>
  );
}
