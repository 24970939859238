import { Link, useLocation } from 'react-router-dom';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import RunGsap from '../../helpers/RunGsap';
import TokenCard from '../../components/TokenCard/TokenCard';
import ButtonContainer, {
  ButtonContainerAlignment,
} from '../../components/ButtonContainer/ButtonContainer';
import Button, { ButtonForm } from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';

function Wallet(props: { experienceTokens: any[] }) {
  let experienceTokens = props.experienceTokens;

  const { search } = useLocation();

  return (
    <div className="page page-Wallet">
      <RunGsap />
      <Module>
        <ModuleText>
          <div className="module-Title">
            <h1>Your XP</h1>
          </div>
        </ModuleText>
        <ModuleText>
          <div className="module-Tokens ">
            {experienceTokens
              .slice()
              .reverse()
              .map((item: any, index) => (
                <TokenCard
                  key={experienceTokens.length - 1 - index}
                  link={`/tokenSingle/${
                    experienceTokens.length - 1 - index
                  }${search}`}
                  img={item.image}
                />
              ))}
            {/* Add up to 5 placeholder tokens */}
            {Array.from({ length: 5 - experienceTokens.length }, (_, index) => (
              <TokenCard key={index} />
            ))}
          </div>
        </ModuleText>
        <ButtonContainer align={ButtonContainerAlignment.Right}>
          <Button path="/" form={ButtonForm.Forward}>
            Collect more
            <div className="icon">
              <IconArrow direction="forward" />
            </div>
          </Button>
        </ButtonContainer>
      </Module>
      <div className="page-Bottom" />
    </div>
  );
}

export default Wallet;
