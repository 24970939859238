import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);

function RunGsapTokenCard() {
  useGSAP(() => {
    gsap.to('.animateTokenCard', {
      opacity: 1,
      y: 0,
      ease: 'power1.inOut',
      duration: 1,
      stagger: {
        amount: 0.165,
      },
    });
  });

  return null;
}

export default RunGsapTokenCard;
